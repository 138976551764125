import { makeAutoObservable, runInAction } from 'mobx'

import type Service from '@src/service'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import Capability, {
  type CodableCapability,
  type CapabilityType,
} from '@src/service/model/Capability'
import type { CapabilityRepository } from '@src/service/worker/repository/CapabilityRepository'

export default class CapabilitiesService {
  private readonly raw: PersistedCollection<Capability, CapabilityRepository>

  constructor(private readonly root: Service) {
    this.subscribeToWebSocket()
    makeAutoObservable(this, {})

    this.raw = new PersistedCollection({
      table: root.storage.table('capability'),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      classConstructor: (json) => new Capability(json),
    })

    this.raw.performQuery((repo) => repo.all())
  }

  load(data: CodableCapability[]) {
    runInAction(() => {
      this.raw.load(data)
    })
  }

  fetch() {
    return this.root.transport.billing.capabilities().then((json) => this.load(json))
  }

  get(type: CapabilityType) {
    return this.raw.get(type)
  }

  get features() {
    return {
      apiMessagingEnabled: this.get('apiMessaging')?.enabled ?? false,
      auditLogEnabled: this.get('auditLog')?.enabled ?? false,
      autoCallRecordingEnabled: this.get('autoCallRecording')?.enabled ?? false,
      autoRepliesEnabled: this.get('autoReplies')?.enabled ?? false,
      businessHoursEnabled: this.get('businessHours')?.enabled ?? false,
      callerIdEnabled: this.get('callerId')?.enabled ?? false,
      callFallbackEnabled: this.get('callFallback')?.enabled ?? false,
      callHoldingEnabled: this.get('callHolding')?.enabled ?? false,
      callingEnabled: this.get('calling')?.enabled ?? false,
      callRecordingEnabled: this.get('callRecording')?.enabled ?? false,
      callTranscriptEnabled: this.get('callTranscription')?.enabled ?? false,
      callTransferEnabled: this.get('callTransfer')?.enabled ?? false,
      conferenceCallingEnabled: this.get('conferenceCalling')?.enabled ?? false,
      contactSuggestionEnabled: this.get('contactSuggestion')?.enabled ?? false,
      emailEnabled: this.get('emailIntegration')?.enabled ?? false,
      gongEnabled: this.get('gong')?.enabled ?? false,
      googleContactsEnabled: this.get('googleContacts')?.enabled ?? false,
      groupMessagingEnabled: this.get('groupMessaging')?.enabled ?? false,
      hubspotEnabled: this.get('hubspot')?.enabled ?? false,
      internationalCallingEnabled: this.get('internationalCalling')?.enabled ?? false,
      internationalMessagingEnabled: this.get('internationalMessaging')?.enabled ?? false,
      ivrEnabled: this.get('ivr')?.enabled ?? false,
      messagingEnabled: this.get('messaging')?.enabled ?? false,
      analyticsReportsEnabled: this.get('reports')?.enabled ?? false,
      ringOrderEnabled: this.get('ringOrder')?.enabled ?? false,
      roundRobinEnabled: this.get('roundRobinCalling')?.enabled ?? false,
      salesforceEnabled: this.get('salesforce')?.enabled ?? false,
      scheduledMessagesEnabled: this.get('scheduledMessages')?.enabled ?? false,
      showOnCallStatusEnabled: this.get('showOnCallStatus')?.enabled ?? false,
      slackEnabled: this.get('slackIntegration')?.enabled ?? false,
      snippetsEnabled: this.get('snippets')?.enabled ?? false,
      userManagementEnabled: this.get('userManagement')?.enabled ?? false,
      voicemailTranscriptionEnabled: this.get('voicemailTranscription')?.enabled ?? false,
      webhooksEnabled: this.get('webhooks')?.enabled ?? false,
      zapierEnabled: this.get('zapier')?.enabled ?? false,
      zapierSms: this.get('zapierSms')?.enabled ?? false,
    }
  }

  get maximumPhoneNumberUsers(): number {
    const capability = this.get('maxPhoneNumberUsers')

    if (capability?.included) {
      return capability.included === -1 ? Infinity : capability.included ?? 10
    }

    return 0
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'capability-update': {
          return this.load(data.capabilities)
        }
      }
    })
  }
}
