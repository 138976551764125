import type { Survicate } from '@survicate/survicate-web-surveys-wrapper/'
import {
  initSurvicate,
  getSurvicateInstance,
  AppearMethod,
} from '@survicate/survicate-web-surveys-wrapper/widget_wrapper'
import { makeAutoObservable, reaction } from 'mobx'

import config from '@src/config'
import { DisposeBag } from '@src/lib/dispose'
import type Service from '@src/service'
import type { UserModel } from '@src/service/model'

export default class SurveyService {
  survey: Survicate | null = null
  private disposeBag = new DisposeBag()

  constructor(root: Service) {
    makeAutoObservable(this)

    this.disposeBag.add(
      reaction(
        () => [root.flags.getFlag('webSurvicate'), root.auth.hasSession] as const,
        ([surveyId, isLoggedIn]) => {
          if (surveyId && isLoggedIn) {
            this.initSurvey(surveyId)
          }
        },
      ),
    )
  }

  private async initSurvey(surveyId: string) {
    if (!surveyId) {
      return
    }

    await initSurvicate({
      workspaceKey: config.SURVICATE_WORKSPACE_KEY,
    })

    this.survey = getSurvicateInstance()
    this.showSurvey(surveyId)
  }

  private showSurvey(id: string) {
    this.survey?.showSurvey(id, {
      displayMethod: AppearMethod.exitIntent,
    })
  }

  async identify(user: UserModel) {
    this.survey?.setVisitorTraits({
      userId: user.id,
    })
  }

  tearDown() {
    this.disposeBag.dispose()
  }
}
