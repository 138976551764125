import { mergeProps } from '@react-aria/utils'
import type { MenuTriggerProps as DefaultMenuTriggerProps } from '@react-stately/menu'
import type { ReactNode, ReactElement } from 'react'
import { useCallback, isValidElement, Children, cloneElement } from 'react'
import type { AriaButtonProps, KeyboardResult } from 'react-aria'
import { useKeyboard } from 'react-aria'
import { isFragment } from 'react-is'

import type { ComboboxTriggerContextProps } from '@ui/Combobox/ComboboxProvider'
import { useComboboxTrigger } from '@ui/Combobox/ComboboxProvider'

type NonPropagatedKeyProps = KeyboardResult['keyboardProps']

type RenderFunctionProps = {
  state: { isOpen: boolean }
  props: Omit<AriaButtonProps<'button'>, 'onPress' | 'onPressStart'> &
    Pick<NonPropagatedKeyProps, 'onKeyDown' | 'onKeyUp'> & {
      onClick: () => void
      ref: ComboboxTriggerContextProps['setNodeRef']
    }
}

type RenderFunction = (props: RenderFunctionProps) => JSX.Element

export interface ComboboxTriggerProps
  extends Omit<AriaButtonProps, 'children'>,
    DefaultMenuTriggerProps {
  /**
   * The children of the ComboboxTrigger, if given a single child it will clone the element and assign a few properties and handlers to convert that child to a combobox trigger.
   *
   * @example
   * ```
   * <Combobox.Trigger>
   *  <button>Open combobox</button>
   * </Combobox.Trigger>
   * ```
   *
   * A render function can also be used to provide the props to an element more deeply nested in the children tree.
   *
   * The props are necessary to open the combobox by clicking on the trigger, or by pressing the arrow down key on the element. Furthermore, it provides of aria attributes that convey that the trigger will open a combobox.
   *
   * @example
   * ```
   * <Combobox.Trigger>
   *  {(props) => (
   *    <Tooltip title="Create thing">
   *      <button {...props}><PlusIcon /></button>
   *    </Tooltip>
   *  )}
   * </Combobox.Trigger>
   * ```
   */
  children: ReactNode | RenderFunction
}

const getFirstChild = (children: ReactNode): ReactElement<Record<string, any>> => {
  const child = Children.toArray(children)[0]

  if (!isValidElement<Record<string, any>>(child) || isFragment(child)) {
    throw new Error('ComboboxTrigger child is invalid')
  }

  return child
}

const isRenderFunction = (
  children: ComboboxTriggerProps['children'],
): children is RenderFunction => typeof children === 'function'

const ComboboxTrigger = ({ children, ...props }: ComboboxTriggerProps) => {
  const {
    ref,
    setNodeRef,
    state: { toggle, isOpen },
    comboboxTriggerProps,
    setTriggeredWith,
  } = useComboboxTrigger()

  const { keyboardProps } = useKeyboard({
    ...comboboxTriggerProps,
    onKeyDown: (evt) => {
      setTriggeredWith({ type: 'keyboard', key: evt.key })
      if (evt.key === 'Tab' || evt.key === 'Escape') {
        evt.continuePropagation()
      }
      comboboxTriggerProps.onKeyDown?.(evt)
    },
  })

  const onClick = useCallback(() => {
    setTriggeredWith({ type: 'pointer' })
    toggle()
  }, [setTriggeredWith, toggle])

  if (isRenderFunction(children)) {
    return children({
      state: { isOpen },
      props: {
        ...removeUnusedHandlers(comboboxTriggerProps),
        onKeyDown: keyboardProps.onKeyDown,
        onKeyUp: keyboardProps.onKeyUp,
        ref: setNodeRef,
        onClick,
      },
    })
  }

  const child = getFirstChild(children)

  const mergedProps = mergeProps(
    child.props,
    props,
    removeUnusedHandlers(comboboxTriggerProps),
    {
      ref,
      onKeyDown: keyboardProps.onKeyDown,
      onClick,
    },
  )
  return cloneElement(child, mergedProps)
}

export default ComboboxTrigger

function removeUnusedHandlers(comboboxTriggerProps: AriaButtonProps) {
  delete comboboxTriggerProps.onPress
  delete comboboxTriggerProps.onPressStart
  return comboboxTriggerProps
}
