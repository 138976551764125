import { observer } from 'mobx-react-lite'
import type { Location } from 'react-router-dom'
import { matchPath } from 'react-router-dom'

import type AppStore from '@src/app/AppStore'
import { AppAvatar, AppAvatarGroup } from '@src/app/components'
import { useAppStore } from '@src/app/context'
import { assertIsNotUndefined } from '@src/lib/isNonNull'
import type { ConversationModel, EntityPhoneNumberModel } from '@src/service/model'
import {
  GongIcon,
  HubspotIcon,
  SalesforceIcon,
  SlackIcon,
  ZapierIcon,
} from '@ui/icons/custom'
import {
  ActivityIcon,
  BlockIcon,
  BuildingIcon,
  ContactsIcon,
  CreditCardIcon,
  FolderIcon,
  FilterSettingDefaultIcon,
  GiftIcon,
  IntegrationsIcon,
  NumberHashIcon,
  PadlockShieldIcon,
  ProfileIcon,
  TeamIcon,
  WebhookIcon,
  AnalyticsIcon,
  VolumeFullIcon,
} from '@ui/icons/tint/20/general'
import { HStack } from '@ui/layout/Stack'

import * as styles from './RecentHistory.css'
import type { HistoryItem } from './RecentHistoryBase'
import RecentHistoryBase from './RecentHistoryBase'

export const MAX_VISIBLE_HISTORY_ITEMS = 12

const getPhoneNumberName = (phoneNumber: EntityPhoneNumberModel): string => {
  // Even though `name` and `formattedNumber` are both nullable,
  // in practice the fallback label will probably not be used.
  return phoneNumber.name || phoneNumber.formattedNumber || 'Phone number'
}

const getConversationName = (conversation: ConversationModel): string => {
  if (conversation.name) {
    return conversation.name
  }

  // Shouldn't happen, but handle it anyway
  if (conversation.participants.length === 0) {
    return 'Conversation'
  }

  // assertIsNotUndefined(conversation.participants[0])

  if (conversation.participants.length === 1) {
    return conversation.participants[0].name
  }

  return `${conversation.participants[0].name} +${conversation.participants.length - 1}`
}

const getIconAndLabel = (
  appStore: AppStore,
  location: Location,
): Pick<HistoryItem, 'icon' | 'label' | 'secondaryLabel'> | null => {
  const { inboxes, service } = appStore

  // Contacts
  if (location.pathname === '/contacts') {
    return { label: 'Contacts', icon: <ContactsIcon /> }
  }

  const contactMatch = matchPath('/contacts/:contactId', location.pathname)

  if (contactMatch) {
    const contact = contactMatch.params.contactId
      ? service.contact.get(contactMatch.params.contactId)
      : null

    return {
      label: contact?.name || 'Unnamed',
      secondaryLabel: 'Contact',
      icon: <AppAvatar identity={contact} size={20} />,
    }
  }

  // Analytics
  if (location.pathname === '/analytics') {
    return { label: 'Analytics', icon: <AnalyticsIcon /> }
  }

  // Settings pages
  if (location.pathname === '/settings/company') {
    return { label: 'General', secondaryLabel: 'Settings', icon: <BuildingIcon /> }
  }

  if (location.pathname === '/settings/members') {
    return { label: 'Members', secondaryLabel: 'Settings', icon: <TeamIcon /> }
  }

  if (location.pathname === '/settings/groups') {
    return { label: 'Groups', secondaryLabel: 'Settings', icon: <FolderIcon /> }
  }

  if (location.pathname === '/settings/phone-numbers') {
    return {
      label: 'Phone numbers',
      secondaryLabel: 'Settings',
      icon: <NumberHashIcon />,
    }
  }

  const phoneNumberMatch = matchPath(
    '/settings/phone-numbers/:phoneNumberId',
    location.pathname,
  )

  if (phoneNumberMatch) {
    const phoneNumber = service.organization.phoneNumber.collection.get(
      phoneNumberMatch.params.phoneNumberId ?? null,
    )

    if (!phoneNumber) {
      return null
    }

    return {
      label: getPhoneNumberName(phoneNumber),
      secondaryLabel: 'Phone number settings',
      icon: (
        <HStack justifyContent="center" alignItems="center" className={styles.icon}>
          {phoneNumber.symbol}
        </HStack>
      ),
    }
  }

  if (location.pathname === '/settings/integrations') {
    return {
      label: 'Integrations',
      secondaryLabel: 'Settings',
      icon: <IntegrationsIcon />,
    }
  }

  if (location.pathname === '/settings/integrations/hubspot') {
    return {
      label: 'HubSpot',
      secondaryLabel: 'Integration settings',
      icon: <HubspotIcon className={styles.icon} />,
    }
  }

  if (location.pathname === '/settings/integrations/salesforce') {
    return {
      label: 'Salesforce',
      secondaryLabel: 'Integration settings',
      icon: <SalesforceIcon className={styles.icon} />,
    }
  }

  if (location.pathname === '/settings/integrations/gong') {
    return {
      label: 'Gong',
      secondaryLabel: 'Integration settings',
      icon: <GongIcon className={styles.icon} />,
    }
  }

  if (location.pathname === '/settings/integrations/zapier') {
    return {
      label: 'Zapier',
      secondaryLabel: 'Integration settings',
      icon: <ZapierIcon className={styles.icon} />,
    }
  }

  if (location.pathname === '/settings/integrations/slack') {
    return {
      label: 'Slack',
      secondaryLabel: 'Integration settings',
      icon: <SlackIcon className={styles.icon} />,
    }
  }

  if (location.pathname === '/settings/billing') {
    return {
      label: 'Plan & billing',
      secondaryLabel: 'Settings',
      icon: <CreditCardIcon />,
    }
  }

  if (location.pathname === '/settings/billing/plans') {
    return {
      label: 'Plans',
      secondaryLabel: 'Plan & billing settings',
      icon: <CreditCardIcon />,
    }
  }

  if (location.pathname === '/settings/trust') {
    return {
      label: 'Trust center',
      secondaryLabel: 'Settings',
      icon: <PadlockShieldIcon />,
    }
  }

  if (location.pathname === '/settings/contacts') {
    return { label: 'Contacts', secondaryLabel: 'Settings', icon: <ContactsIcon /> }
  }

  if (location.pathname === '/settings/blocklist') {
    return { label: 'Blocklist', secondaryLabel: 'Settings', icon: <BlockIcon /> }
  }

  if (location.pathname === '/settings/webhooks') {
    return { label: 'Webhooks', secondaryLabel: 'Settings', icon: <WebhookIcon /> }
  }

  // FIXME:
  // https://linear.app/openphone/issue/WORK-3579/webhook-details-page-doesnt-update-properly-when-the-webhook-props
  //
  // The code below works but we're leaving it commented out until the
  // above issue is resolved. The problem is that the WebhookDetails
  // component doesn't take into account the webhook prop changing
  // after the component has been rendered, so it's not a good user
  // experience if the user switches back and forth between two
  // webhooks details pages via the Recent History menu.

  /*
  const webhookMatch = matchPath('/settings/webhooks/:webhookId', location.pathname)
  if (webhookMatch) {
    const webhook = workspace.webhooks.find(
      (w) => w.id === webhookMatch.params.webhookId,
    )

    if (!webhook) {
      return null
    }

    return {
      label: `Settings ∙ Webhooks ∙ ${webhook.label || webhook.url}`,
      icon: <WebhookIcon />,
    }
  }
  */

  if (location.pathname === '/settings/profile') {
    return { label: 'Profile', secondaryLabel: 'Settings', icon: <ProfileIcon /> }
  }

  if (location.pathname === '/settings/preferences') {
    return {
      label: 'Preferences',
      secondaryLabel: 'Settings',
      icon: <FilterSettingDefaultIcon />,
    }
  }

  if (location.pathname === '/settings/audio-settings') {
    return {
      label: 'Audio settings',
      secondaryLabel: 'Settings',
      icon: <VolumeFullIcon />,
    }
  }

  if (location.pathname === '/settings/notifications') {
    return {
      label: 'Notifications',
      secondaryLabel: 'Settings',
      icon: <ActivityIcon />,
    }
  }

  if (location.pathname === '/settings/referrals') {
    return { label: 'Referrals', secondaryLabel: 'Settings', icon: <GiftIcon /> }
  }

  // Inbox without convo selected
  const inboxMatch = matchPath('/inbox/:inboxId', location.pathname)

  if (inboxMatch) {
    const phoneNumber = service.organization.phoneNumber.collection.get(
      inboxMatch.params.inboxId ?? null,
    )

    if (!phoneNumber) {
      return null
    }

    return {
      label: getPhoneNumberName(phoneNumber),
      secondaryLabel: 'Inbox - Chats',
      icon: (
        <HStack justifyContent="center" alignItems="center" className={styles.icon}>
          {phoneNumber.symbol}
        </HStack>
      ),
    }
  }

  // Inbox with convo selected
  const conversationMatch = matchPath(
    '/inbox/:inboxId/c/:conversationId',
    location.pathname,
  )

  if (conversationMatch) {
    // DMs
    if (
      conversationMatch.params.inboxId ===
      service.user.current?.asMember?.directNumber?.id
    ) {
      const conversation = inboxes.dm?.conversations.list.find(
        (c) => c.id === conversationMatch.params.conversationId,
      )
      const member = service.member.collection.list.find(
        (m) => m.directNumber?.number === conversation?.phoneNumber,
      )

      if (!conversation || !member) {
        return null
      }

      return {
        label: conversation.friendlyName,
        secondaryLabel: 'Direct message',
        icon: <AppAvatar size={20} identity={member} />,
      }
    }

    // Inbox conversations
    const conversation = service.conversation.collection.get(
      conversationMatch.params.conversationId ?? null,
    )

    const phoneNumber = service.organization.phoneNumber.collection.get(
      conversationMatch.params.inboxId ?? null,
    )

    if (!conversation || !phoneNumber) {
      return null
    }

    return {
      label: getConversationName(conversation),
      secondaryLabel: `in ${phoneNumber.symbol} ${phoneNumber.name}`,
      icon: <AppAvatarGroup size={20} identities={conversation.participants} />,
    }
  }

  // Inbox calls view
  const callsViewMatch = matchPath('/inbox/:inboxId/calls/*', location.pathname)

  if (callsViewMatch) {
    const phoneNumber = service.organization.phoneNumber.collection.get(
      callsViewMatch.params.inboxId ?? null,
    )

    if (!phoneNumber) {
      return null
    }

    return {
      label: getPhoneNumberName(phoneNumber),
      secondaryLabel: 'Inbox - Calls',
      icon: (
        <HStack justifyContent="center" alignItems="center" className={styles.icon}>
          {phoneNumber.symbol}
        </HStack>
      ),
    }
  }

  // disregard any unhandled paths
  return null
}

const RecentHistory = () => {
  const app = useAppStore()
  const { history } = app

  const historyItems: HistoryItem[] = history.locationHistory
    .map((location) => {
      const iconAndLabel = getIconAndLabel(app, location)

      if (!iconAndLabel) {
        return null
      }

      const { icon, label, secondaryLabel } = iconAndLabel

      return {
        id: location.key,
        icon,
        label,
        secondaryLabel,
        location,
      }
    })
    .reverse()
    .reduce<{ items: HistoryItem[]; hasCallsViewItemByInboxId: Record<string, boolean> }>(
      (acc, item) => {
        // skip null items
        if (item === null) {
          return acc
        }

        const callsViewMatch = matchPath(
          '/inbox/:inboxId/calls/*',
          item.location.pathname,
        )

        // We only want to show one calls view item per inbox which should
        // direct the user to `inbox/:inboxId/calls`. At that point, the calls
        // view UI logic will handle auto-selecting the first call in the list.
        if (callsViewMatch) {
          const inboxId = callsViewMatch.params.inboxId
          // early return to avoid an index type typescript error below
          if (!inboxId) {
            return acc
          }

          // skip if we already have a calls view item for this inbox
          if (acc.hasCallsViewItemByInboxId[inboxId]) {
            return acc
          }

          // mark that we have a calls view item for this inbox to
          // perform the skip above
          acc.hasCallsViewItemByInboxId[inboxId] = true
          acc.items.push({
            ...item,
            location: {
              ...item.location,
              // update the pathname for a simpler redirect experience
              pathname: `/inbox/${inboxId}/calls`,
            },
          })
          return acc
        }

        // add the rest of the items
        acc.items.push(item)
        return acc
      },
      { items: [], hasCallsViewItemByInboxId: {} },
    )
    .items.slice(0, MAX_VISIBLE_HISTORY_ITEMS)

  return (
    <RecentHistoryBase
      historyItems={historyItems}
      onSelection={history.historyNavigate}
    />
  )
}

export default observer(RecentHistory)
