import * as Sentry from '@sentry/react'
import type { RequiredFieldLogger } from 'relay-runtime'

export default function relayFieldLogger(fieldError: Parameters<RequiredFieldLogger>[0]) {
  const tags = {
    relay_log_kind: fieldError.kind,
    relay_log_fieldPath: fieldError.fieldPath,
    relay_log_owner: fieldError.owner,
  }

  switch (fieldError.kind) {
    case 'missing_field.log':
    case 'missing_field.throw': {
      Sentry.captureException(
        new Error(`${fieldError.kind} at ${fieldError.fieldPath} in ${fieldError.owner}`),
        {
          tags,
        },
      )
      break
    }
    case 'relay_resolver.error': {
      const { error } = fieldError
      Sentry.captureException(error, {
        tags,
      })
      break
    }
  }
}
