const searchParamsWhitelist = [
  '_ga', // Google Analytics cross-domain tracking
  'gspk', // PartnerStack (deprecated on January 2024)
  'gsxid', // PartnerStack (deprecated on January 2024)
  'ps_partner_key', // PartnerStack
  'ps_xid', // PartnerStack
  'inviteCode',
  'state',
  'code',
  'at',
  'handlerUrl',
  'invite',
  'referral_code',
  'email',
  'call',
  'number',
  'modal',
  'scope',
  'client_id',
  'redirect_uri',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_content',
  'utm_term',
  // FIXME: remove these session token query parameters after users have been
  // migrated from 3.15.7, see https://linear.app/openphone/issue/ENG-4864
  'session_id_token',
  'session_refresh_token',
  'blockId',
] as const

export default searchParamsWhitelist
