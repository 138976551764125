/**
 * Generates a v4 uuid
 *
 * If `randomUUID` method in crypto is not present, which might be likeli on Safari
 * where it's been added in recent versions we fall back to a generator using
 * the `getRandomValues` method which has full support
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Crypto/randomUUID
 */
export default function uuid() {
  if ('randomUUID' in crypto) {
    return crypto.randomUUID()
  }

  return `${1e7}${-1e3}${-4e3}${-8e3}${-1e11}`.replace(/[018]/g, (c: string) =>
    (
      Number(c) ^
      // @ts expect-error noUncheckedIndexAccess
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))
    ).toString(16),
  )
}
