/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import config from '@src/config'
import { whitelist } from '@src/lib'
import type {
  CodableContact,
  CodableGoogleContactSettings,
  CodableSharedContactSettings,
  RawContactSuggestion,
} from '@src/service/model'
import type {
  ContactSuggestionStatus,
  SuggestedFields,
} from '@src/service/model/ContactSuggestionModel'
import type { CodableContactTemplateItem } from '@src/service/model/ContactTemplateItemModel'
import type {
  CodableCsvImportV2,
  CsvImportStatus,
  CsvImportV2MappingValue,
} from '@src/service/model/CsvImportV2Model'
import type { EncodableNote } from '@src/service/model/NoteModel'
import type { BaseEncodableReaction } from '@src/service/model/reactions/BaseReaction'

import type Transport from '.'
import type Paginated from './lib/Paginated'
import { HttpTransaction } from './transaction'

export type VisibilityType = 'private' | 'org' | 'shared-number' | 'global'

export interface Contact {
  id?: string
  local?: boolean
  visibility?: VisibilityType
  source?: string
  sourceName?: string
  userId?: string
  firstName?: string
  lastName?: string
  company?: string
  role?: string
  location?: string
  pictureUrl?: string
  items: ContactItem[]
  sharedWith?: string[]
  createdAt?: number | Date
  updatedAt?: number | Date
  notes?: ContactNote[]

  // To look up contacts by phone numbers quickly on IndexedDB
  _phoneNumbers?: string[]
}

export interface ContactItem {
  id?: string
  templateKey?: string
  name?: string
  type?: ContactItemType
  value?: any
  deletedAt?: number
  createdAt?: number
  updatedAt?: number
}

export type ContactItemType =
  | 'address'
  | 'boolean'
  | 'company'
  | 'date'
  | 'email'
  | 'multi-select'
  | 'number'
  | 'phone-number'
  | 'role'
  | 'string'
  | 'url'
  | 'created-by'
  | 'access'

export interface ContactNote {
  private?: boolean | null
  text?: string | null
  userId?: string | null
  objectId?: string
  deletedAt?: Date | number | null
  createdAt?: Date | number | null
  updatedAt?: Date | number | null
  id?: string
}

export default class ContactsClient {
  constructor(private transport: Transport) {}

  fetch(query: {
    since?: Date
    limit?: number
    lastId?: string
    includeDeleted?: boolean
  }): Promise<Paginated<Contact>> {
    return this.transport.queue(
      new HttpTransaction({ url: `${config.CONTACT_SERVICE_URL}contact`, query }),
    )
  }

  googleSync(code: string, redirectUri: string): Promise<any> {
    code = encodeURIComponent(code)
    redirectUri = encodeURIComponent(redirectUri)
    return this.transport.queue(
      new HttpTransaction({
        url: `${config.CONTACT_SERVICE_URL}contact/people/sync?code=${code}&redirectUri=${redirectUri}`,
        method: 'post',
      }),
    )
  }

  createBulk(contacts: CodableContact[]) {
    return this.transport.queue(
      new HttpTransaction({
        method: 'post',
        url: `${config.CONTACT_SERVICE_URL}contact`,
        body: contacts,
        queue: 'contact-import',
        retry: true,
      }),
    )
  }

  async update(contact: CodableContact): Promise<any> {
    const cont = whitelist(contact, [
      'id',
      'firstName',
      'lastName',
      'company',
      'role',
      'items',
      'location',
      'pictureUrl',
      'source',
      'sourceName',
      'userId',
      'sharedWith',
    ])
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.CONTACT_SERVICE_URL}contact/${contact.id}`,
        body: cont,
        queue: contact.id,
        retry: true,
      }),
    )
  }

  merge(id: string, withIds: string[]): Promise<{ results: 'ok'; newContactId: string }> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.CONTACT_SERVICE_URL}contact/${id}/merge`,
        body: withIds,
        queue: id,
        retry: true,
      }),
    )
  }

  delete(id: string): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'delete',
        url: `${config.CONTACT_SERVICE_URL}contact/${id}`,
        queue: id,
        retry: true,
      }),
    )
  }

  shareBulk(ids: string[], sharedIds: string[], overrideSharing = true): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'put',
        url: `${config.CONTACT_SERVICE_URL}contact/share`,
        body: { contactIds: ids, sharedIds, overrideSharing },
        queue: 'bulk-contact-share',
        retry: true,
      }),
    )
  }

  deleteBulk(ids: string[]): Promise<void> {
    return this.transport.queue(
      new HttpTransaction({
        method: 'delete',
        url: `${config.CONTACT_SERVICE_URL}contact`,
        body: ids,
        queue: 'contact-delete',
        retry: true,
      }),
    )
  }

  note = {
    put: (contactId: string, note: EncodableNote): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.CONTACT_SERVICE_URL}contact/${contactId}/note`,
          body: note,
          queue: contactId,
          retry: true,
        }),
      )
    },

    deleteNote: (contactId: string, noteId: string): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.CONTACT_SERVICE_URL}contact/${contactId}/note/${noteId}`,
          queue: contactId,
          retry: true,
        }),
      )
    },

    addReaction: (
      contactId: string,
      noteId: string,
      reaction: BaseEncodableReaction,
    ): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.CONTACT_SERVICE_URL}contact/${contactId}/note/${noteId}/reaction`,
          body: reaction,
          retry: true,
        }),
      )
    },

    deleteReaction: (contactId: string, noteId: string, reactionId: string) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.CONTACT_SERVICE_URL}contact/${contactId}/note/${noteId}/reaction/${reactionId}`,
          retry: true,
        }),
      )
    },
  }

  csv = {
    import: (body: {
      fileName: string
      name: string
      type: string
      mapping: Record<string, CsvImportV2MappingValue>
      meta: object
    }): Promise<CodableCsvImportV2 & { s3SignedUrl: string }> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.CONTACT_SERVICE_URL}import`,
          body,
          queue: 'contact-import',
        }),
      )
    },

    getAllImports: (includeDeleted = true): Promise<CodableCsvImportV2[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.CONTACT_SERVICE_URL}import`,
          query: { includeDeleted },
        }),
      )
    },

    importStatus: (id: string, jobMeta: { status: CsvImportStatus; retries: number }) => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.CONTACT_SERVICE_URL}import/${id}`,
          method: 'put',
          body: {
            job: jobMeta,
          },
        }),
      )
    },

    submitFile: (file: File, url: string) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url,
          body: file,
          queue: 'contact-csv-file',
          headers: {
            'Content-Type': 'text/csv',
          },
        }),
      )
    },

    deleteImport: (id: string) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.CONTACT_SERVICE_URL}import/${id}`,
        }),
      )
    },
  }

  template = {
    fetch: (): Promise<CodableContactTemplateItem[]> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.CONTACT_SERVICE_URL}contact/template` }),
      )
    },

    put: (item: CodableContactTemplateItem): Promise<CodableContactTemplateItem> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.CONTACT_SERVICE_URL}contact/template/${item.id}`,
          body: item,
          queue: item.id,
          retry: true,
        }),
      )
    },

    create: (item: CodableContactTemplateItem): Promise<CodableContactTemplateItem> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.CONTACT_SERVICE_URL}contact/template`,
          body: item,
          queue: item.id,
          retry: true,
        }),
      )
    },

    delete: (item: CodableContactTemplateItem): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.CONTACT_SERVICE_URL}contact/template/${item.id}`,
          queue: item.id,
          retry: true,
        }),
      )
    },
  }

  settings = {
    fetchGoogleSettings: (): Promise<CodableGoogleContactSettings[]> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.CONTACT_SERVICE_URL}contact/settings`,
        }),
      )
    },

    fetch: (): Promise<CodableSharedContactSettings> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.CONTACT_SERVICE_URL}settings`,
        }),
      )
    },

    put: (
      settings: Partial<
        Pick<CodableSharedContactSettings, 'defaultSharingIds' | 'suggestionsEnabled'>
      >,
    ) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.CONTACT_SERVICE_URL}settings`,
          body: settings,
        }),
      )
    },

    resync: (settings: CodableGoogleContactSettings) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'post',
          url: `${config.CONTACT_SERVICE_URL}contact/people/sync?source=${settings.source}`,
          retry: false,
        }),
      )
    },

    delete: (id: string) => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'delete',
          url: `${config.CONTACT_SERVICE_URL}contact/settings/${id}`,
          retry: true,
        }),
      )
    },
  }

  suggestions = {
    for: (orgId: string) => ({
      fetchPending: (): Promise<{ result: RawContactSuggestion[] }> => {
        return this.transport.queue(
          new HttpTransaction({
            url: `${config.CONTACT_SERVICE_URL}suggestions/org/${orgId}`,
            query: { status: 'pending' },
          }),
        )
      },
    }),

    update: (
      id: string,
      status: Omit<ContactSuggestionStatus, 'pending'>,
      suggestedFields: SuggestedFields,
    ): Promise<RawContactSuggestion> => {
      return this.transport.queue(
        new HttpTransaction({
          method: 'put',
          url: `${config.CONTACT_SERVICE_URL}suggestions/${id}`,
          body: {
            status,
            suggestedFields,
          },
        }),
      )
    },
  }
}
