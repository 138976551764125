import cx from 'classnames'
import type { CSSProperties, ReactElement } from 'react'
import { cloneElement, forwardRef } from 'react'

import * as styles from './Badge.css'
import type {
  BadgeVariant,
  BadgeSize,
  BadgeColor,
  BadgeIconPlacement,
} from './Badge.types'

export interface BadgeProps {
  variant?: BadgeVariant
  color?: BadgeColor
  size?: BadgeSize
  icon?: ReactElement
  label: string
  style?: CSSProperties
  className?: string
  iconPlacement?: BadgeIconPlacement
}

const Badge = (
  {
    variant = 'default',
    color = 'primary',
    size = 'default',
    icon: iconProp,
    label,
    style,
    className,
    iconPlacement = 'start',
    ...props
  }: BadgeProps,
  ref: React.ForwardedRef<HTMLSpanElement>,
) => {
  const iconPropClassName: string | null =
    iconProp &&
    typeof iconProp.props === 'object' &&
    'className' in iconProp.props &&
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    typeof iconProp.props.className === 'string'
      ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        (iconProp.props.className as string)
      : null

  const icon = iconProp
    ? cloneElement(iconProp, {
        className: cx(iconPropClassName, styles.icon({ size })),
      })
    : null

  return (
    <span
      ref={ref}
      className={cx(
        styles.root({
          variant,
          size,
          color,
          iconPlacement: icon ? iconPlacement : undefined,
        }),
        className,
      )}
      style={style}
      {...props}
    >
      {iconPlacement === 'start' && icon}
      {label}
      {iconPlacement === 'end' && icon}
    </span>
  )
}

export default forwardRef(Badge)
