/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { makeAutoObservable, runInAction } from 'mobx'

import PersistedCollection from '@src/service/collections/PersistedCollection'
import type { ActivityDecodableReaction } from '@src/service/model/reactions/ActivityReaction'
import ActivityReaction, {
  isActivityReaction,
} from '@src/service/model/reactions/ActivityReaction'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import Collection from './collections/Collection'
import type { Conversation } from './conversation-store'
import { ActivityModel } from './conversation-store'
import type { PostCallActivitiesRequest } from './dto/request/activities/calls'
import type { CodableMessageMedia } from './model'
import type { TransactionConfiguration } from './transport/transaction'
import type { ActivityRepository } from './worker/repository/activity'

export interface FetchActivitiesBeforeParams {
  conversationId: string | null
  id: string

  // For loading activities
  beforeId?: string | null
  type?: ActivityModel['type']
}

export interface FetchActivitiesAfterParams {
  conversationId: string | null
  id: string

  // For loading activities
  afterId?: string | null
  type?: ActivityModel['type']
}

export default class ActivityStore {
  readonly collection: PersistedCollection<ActivityModel, ActivityRepository>

  /**
   * A collection of voicemail activities, indexed by their SID
   * Used for linking `call` activities to their corresponding `voicemail` activity.
   */
  private readonly voicemailActivitiesBySid = new Collection<ActivityModel>({
    idKey: 'sid',
  })
  private lastFetchedAt: { [key: string]: number } = {}

  constructor(private service: Service) {
    this.collection = new PersistedCollection({
      table: service.storage.table('activity'),
      classConstructor: () => new ActivityModel(),
    })

    makeAutoObservable(this, {})

    makePersistable<this, 'lastFetchedAt' | 'pageInfos'>(this, 'ActivityStore', {
      lastFetchedAt: service.storage.async(),
      pageInfos: service.storage.async(),
    })

    this.handleWebsocket()

    this.collection.observe((changes) => {
      const voicemails = changes.objects.filter((o) => o.type === 'voicemail')

      if (changes.type === 'put') {
        this.voicemailActivitiesBySid.putBulk(voicemails)
      } else if (changes.type === 'delete') {
        this.voicemailActivitiesBySid.deleteBulk(voicemails)
      }
    })
  }

  get(id: string) {
    return this.collection.get(id)
  }

  getVoicemailBySid(sid: string) {
    return this.voicemailActivitiesBySid.get(sid)
  }

  /**
   * Tries to get an activity from memory first, then the underlying storage
   */
  getById = async (id: string): Promise<ActivityModel | null> => {
    let activity = this.collection.get(id, { skipStorage: true })

    if (activity) {
      return activity
    }

    activity = (await this.collection.performQuery((repo) => repo.get(id))) ?? null

    if (activity) {
      return activity
    }

    return null
  }

  sendRaw(params: {
    to: string
    directNumberId?: string
    phoneNumberId?: string
    body: string
    media?: CodableMessageMedia[]
  }) {
    return this.service.transport.communication.activities.send(params)
  }

  loadByIds(ids: string[]) {
    ids = ids.filter((id) => !this.collection.isInMemory(id))
    return this.collection.performQuery((repo) => repo.getBulk(ids))
  }

  async send(activity: ActivityModel) {
    if (!activity.conversationId) {
      return Promise.reject('Can not send an activity without a conversation id')
    }

    this.service.conversation.historyService.addActivityToCache(activity)

    runInAction(() => {
      activity.status = 'queued'
      this.collection.put(activity)
    })

    const conversation = this.service.conversation.collection.get(activity.conversationId)

    if (conversation) {
      runInAction(() => {
        conversation.lastActivityId = activity.id
        conversation.lastActivityAt = activity.createdAt ?? Date.now()
        conversation.save()
      })
    }

    try {
      await Promise.all(activity.media.map((m) => this.service.media.upload(m)))

      const { conversation: conversationJson, activity: activityJson } =
        await this.service.transport.communication.activities.send({
          body: activity.body,
          media: activity.media,
          id: activity.id,
          conversationId: conversation?.id,
          phoneNumberId: conversation?.phoneNumberId,
          directNumberId: conversation?.directNumberId,
          to: conversation?.phoneNumber,
          sendAndMarkDone: activity.options?.markAsDone,
        })

      this.service.conversation.collection.load(conversationJson)

      if (activity.type === 'message') {
        if (conversation?.directNumberId) {
          this.service.analytics.inbox.directMessageSent()
        } else {
          this.service.analytics.inbox.messageSent()
        }
      }

      const activityCollection = await this.collection.load(activityJson)
      return activityCollection[0]
    } catch (error) {
      runInAction(() => {
        activity.status = 'failed'
        activity.error = {
          code: 400,
          message: 'Failed to send',
        }
        if (
          typeof error === 'object' &&
          error &&
          'message' in error &&
          typeof error.message === 'string'
        ) {
          activity.error.secondaryMessage = error.message
        }
        this.collection.put(activity)

        throw error
      })
    }
  }

  delete = (activity: ActivityModel) => {
    this.collection.delete(activity)
  }

  private async addReaction(reaction: ActivityReaction) {
    try {
      reaction.activity.reactions.push(reaction)
      this.collection.put(reaction.activity)
      return await this.service.transport.communication.reactions.post(reaction.toJSON())
    } catch (error) {
      reaction.activity.deleteReactionLocally(reaction)
      throw error
    }
  }

  private async deleteReaction(reaction: ActivityReaction) {
    try {
      reaction.activity.deleteReactionLocally(reaction)
      this.collection.put(reaction.activity)
      return await this.service.transport.communication.reactions.delete(reaction.id)
    } catch (error) {
      reaction.activity.reactions.push(reaction)
      throw error
    }
  }

  getReaction(emoji: string, reactions: ActivityReaction[]) {
    if (!this.service.user.current) {
      return undefined
    }

    return this.service.emoji.getReaction(
      emoji,
      this.service.emoji,
      reactions,
      this.service.user.current.id,
    )
  }

  toggleReaction(activity: ActivityModel, emoji: string) {
    if (!this.service.user.current) {
      return
    }

    const reaction = this.getReaction(emoji, activity.reactions)
    if (reaction) {
      return this.deleteReaction(reaction)
    } else {
      const body = this.service.emoji.getEmojiWithSkinTone(emoji)
      return this.addReaction(
        new ActivityReaction(activity, {
          body,
          userId: this.service.user.current.id,
        }),
      )
    }
  }

  resolve(activity: ActivityModel) {
    if (!this.service.user.current) {
      return
    }
    activity.resolvedAt = Date.now()
    activity.resolvedBy = this.service.user.current.id
    return this.service.transport.communication.activities.resolve(activity.id)
  }

  unresolve(activity: ActivityModel) {
    activity.resolvedAt = null
    activity.resolvedBy = null
    return this.service.transport.communication.activities.unresolve(activity.id)
  }

  deleteRecordings(activity: ActivityModel, mediaIds: string[]) {
    this.service.voice.recordings.bulkDelete(activity.id, mediaIds)
    activity.media = activity.media.filter((item) => !mediaIds.includes(item.id))
  }

  /**
   * Fetches the most recently updated activities for a given conversation from
   * the backend.
   *
   * Makes use of the `lastFetchedAt` property to only fetch activities with changes
   * since the last time we fetched activities for this conversation.
   *
   * @param conversation
   * @returns
   */
  fetchRecentChanges = async (conversation: Conversation) => {
    const lastFetchedAt = this.lastFetchedAt[conversation.id]
    const since = lastFetchedAt ? new Date(lastFetchedAt) : undefined

    if (conversation.isNew) {
      return []
    }

    const { result } = await this.service.transport.communication.activities.list({
      id: conversation.id,
      since,
    })

    const activities = await this.collection.load(result)

    runInAction(() => {
      this.lastFetchedAt[conversation.id] = Math.max(
        lastFetchedAt || 0,
        ...activities.map((c) => c.updatedAt ?? 0),
      )
    })
  }

  fetchCallActivities = async (
    params: PostCallActivitiesRequest,
    transactionConfiguration?: TransactionConfiguration,
  ) => {
    const response = await this.service.transport.communication.activities.calls.list(
      params,
      transactionConfiguration,
    )

    const { calls, voicemails, pageInfo } = response ?? {
      calls: [],
      voicemails: [],
      pageInfo: {
        startId: null,
        endId: null,
        prevId: null,
        nextId: null,
      },
    }

    const [callActivities] = await Promise.all([
      calls ? this.collection.load(calls) : Promise.resolve([]),
      voicemails ? this.collection.load(voicemails) : Promise.resolve([]),
    ])

    return { activities: callActivities, pageInfo }
  }

  private handleWebsocket() {
    this.service.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'reaction-update':
          if (isActivityReaction(msg.reaction)) {
            return this.handleReactionUpdate(msg.reaction)
          }
          break
        case 'reaction-delete':
          if (isActivityReaction(msg.reaction)) {
            return this.handleReactionDelete(msg.reaction)
          }
          break
      }
    })
  }

  private handleReactionUpdate(value: ActivityDecodableReaction) {
    const activity = this.collection.get(value.activityId)
    if (!activity) {
      return
    }
    const object = value.commentId
      ? activity.comments.find((comment) => comment.id === value.commentId)
      : activity
    if (!object) {
      return
    }
    const reaction = object.reactions.find((reaction) => reaction.id == value.id)
    if (reaction) {
      reaction.deserialize(value)
    } else {
      object.reactions.push(new ActivityReaction(object, value))
    }
  }

  private handleReactionDelete(value: ActivityDecodableReaction) {
    const activity = this.collection.get(value.activityId)
    if (!activity) {
      return
    }
    const reaction = activity.reactions.find((reaction) => reaction.id == value.id)

    if (reaction) {
      return activity.deleteReactionLocally(reaction)
    }

    // TODO: Get the backend to return the commentId associated with a deleted reaction on a comment via websocket
    // Checks comments (instead of activities) for a reaction to delete
    activity.comments.forEach((comment) => {
      const commentReactionToRemove = comment.reactions.findIndex(
        (reaction) => reaction.id === value.id,
      )
      if (commentReactionToRemove >= 0) {
        comment.reactions.splice(commentReactionToRemove, 1)
      }
    })
  }
}
