import Typography from '@material-ui/core/Typography'
import cx from 'classnames'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import AnimatingLogo from '@src/app/components/AnimatingLogo'
import Button from '@src/component/button'
import { fromQueryString, toQueryString } from '@src/lib/query-string'

import * as styles from './NativeLogin.css'
import { redirectGoogleLogin } from './google-login'

function NativeLogin() {
  const location = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
  const [result, setResult] = useState<any>(null)

  useEffect(() => {
    const params = fromQueryString(location.hash)
    if (params?.access_token) {
      setResult(params)
      redirectToApp(params)
    } else {
      redirectGoogleLogin()
    }
  }, [location.hash])

  const redirectToApp = (result: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    window.location.replace(`openphone://login${toQueryString(result)}`)
  }

  return (
    <div className={cx(styles.root, { [styles.redirect]: !result })}>
      {result && (
        <>
          <AnimatingLogo />
          <Typography style={{ marginTop: 30 }} variant="body2" color="textSecondary">
            Redirecting you to the desktop app...
          </Typography>
          <Button
            style={{ marginTop: 10 }}
            variant="text"
            color="primary"
            onClick={() => redirectToApp(result)}
          >
            Click here if it's taking too long
          </Button>
        </>
      )}
    </div>
  )
}

export default NativeLogin
