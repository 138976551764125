import isEqualWith from 'lodash/fp/isEqualWith'
import isObjectLike from 'lodash/fp/isObjectLike'
import isPlainObject from 'lodash/fp/isPlainObject'
import type { PredicateFunction, PredicateType } from 'ts-essentials'

import type ById from '@src/lib/ById'

import isNonNull from './isNonNull'

function isReadonlyArray(collection: unknown): collection is readonly unknown[] {
  return Array.isArray(collection)
}

function isReadonlyArrayOf<T>(
  collection: unknown,
  guard: (t: unknown) => t is T,
): collection is readonly T[] {
  return isReadonlyArray(collection) && guard(collection[0])
}

export function isArray(collection: unknown): collection is unknown[] {
  return isReadonlyArray(collection)
}

export function combineGuards<
  Predicates extends PredicateFunction[],
  Types extends PredicateType<Predicates[number]>,
>(...guards: Predicates): (item: unknown) => item is Types {
  return (item: unknown): item is Types => guards.some((guard) => guard(item))
}

export function isArrayOf<
  Predicates extends PredicateFunction[],
  Types extends PredicateType<Predicates[number]>,
>(...guards: Predicates): (collection: unknown) => collection is Types[] {
  const arrayOfGuards = guards.map(
    (guard) =>
      (collection: unknown): collection is PredicateType<typeof guard> =>
        isReadonlyArrayOf(collection, guard),
  )
  return (collection: unknown): collection is Types[] =>
    combineGuards(...arrayOfGuards)(collection)
}

export const isString = (item: unknown): item is string => typeof item === 'string'

export const isNonEmptyString = (item: unknown): item is string =>
  isString(item) && item.length > 0

export const isNumber = (item: unknown): item is number => typeof item === 'number'

export function isArrayOfStrings(collection: unknown): collection is string[] {
  return isArrayOf(isString)(collection)
}

export function random<T>(collection: T[]): T {
  return collection[Math.floor(Math.random() * collection.length)]
}

function hasIndex(collection: unknown[], index: number): boolean {
  return index >= 0 && index < collection.length
}

export function removeAtIndex<T>(collection: T[], index: number): T[] {
  return hasIndex(collection, index)
    ? [...collection.slice(0, index), ...collection.slice(index + 1)]
    : collection
}

export function insertAtIndex<T>(collection: T[], item: T, index: number): T[] {
  return [...collection.slice(0, index), item, ...collection.slice(index)]
}

export function replaceAtIndex<T>(collection: T[], item: T, index: number): T[] {
  return [...collection.slice(0, index), item, ...collection.slice(index + 1)]
}

export function omit<
  Key extends string | number,
  Collection extends Record<Key, unknown>,
>(collection: Collection, ...keys: Key[]) {
  const lastKey = keys.pop()

  if (!lastKey) {
    return collection
  }

  const newCollection = { ...collection }
  delete newCollection[lastKey]

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
  return omit(newCollection, ...keys)
}

export function strictOmit<Collection, Key extends keyof Collection>(
  collection: Collection,
  keys: Key[],
): Omit<Collection, (typeof keys)[number]> {
  const clone = { ...collection }

  for (const key of keys) {
    delete clone[key]
  }

  return clone
}

export type PartitionedArray<T> = [pass: T[], fail: T[]]

export function partition<T>(
  collection: readonly T[],
  filter: (item: T) => boolean,
): PartitionedArray<T> {
  const pass: T[] = []
  const fail: T[] = []
  collection.forEach((item) => {
    filter(item) ? pass.push(item) : fail.push(item)
  })
  return [pass, fail]
}

export function last<T>(collection: readonly T[]): T | undefined {
  if (!collection || collection.length === 0) {
    return undefined
  }
  return collection[collection.length - 1]
}

export function map<T>(objects: T[], by: keyof T): ById<T> {
  return objects.reduce((final, obj) => {
    if (!obj) {
      return final
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    final[obj[by] as any] = obj
    return final
  }, {})
}

export function whitelist<T extends object>(source: T, properties: (keyof T)[]): T {
  const obj = {} as T
  properties.forEach((property) => {
    if (property in source && typeof source[property] !== 'undefined') {
      obj[property] = source[property]
    }
  })
  return obj
}

export function unique<T>(source: T[]): T[] {
  return [...new Set<T>(source)]
}

/**
 * Returns a new array without duplicates and filtered by the type guards provided
 *
 * @example
 * ```ts
 * uniqueOf(isString, isNumber)(['a', 'a', 'b', 1, 1, 2, null, undefined]) -> ['a', 'b', 1, 2]
 * ```
 */
export function uniqueOf<
  Predicates extends PredicateFunction[],
  Types extends PredicateType<Predicates[number]>,
>(...guards: Predicates): (collection: unknown[]) => Types[] {
  const isAnyOf = (item: unknown): item is Types => guards.some((guard) => guard(item))
  return (collection: unknown[]): Types[] => unique(collection.filter(isAnyOf))
}

export function uniqueBy<T>(source: T[], mapper: (t: T) => unknown): T[] {
  const set = new Set<unknown>()

  return source.filter((t) => {
    const mapped = mapper(t)
    const exists = set.has(mapped)
    set.add(mapped)
    return !exists
  })
}

export function uniqueAndNonNull<T>(source: T[]): NonNullable<T>[] {
  return [...new Set<T>(source)].filter(isNonNull)
}

export function chunk<T>(arr: T[], chunkSize: number): T[][] {
  const R: T[][] = []
  for (let i = 0, len = arr.length; i < len; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize))
  }
  return R
}

export function isEmpty(object: any) {
  if (typeof object === 'object') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    if (object.length) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      object.length === 0
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      return Object.keys(object).length === 0
    }
  }
  if (!object) {
    return true
  }
}

export function toCamelCase(
  object: { [key: string]: any } | string | any[] | boolean | number,
): any {
  if (!object || typeof object === 'number' || typeof object === 'boolean') {
    return object
  }
  if (typeof object === 'string') {
    // @ts expect-error noUncheckedIndexAccess
    return object.replace(/_([a-z])/g, (char) => char[1].toUpperCase())
  } else if (object instanceof Array) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    return object.map((obj) => toCamelCase(obj))
  }
  return Object.keys(object).reduce((final, key) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    let value = object[key]
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const newKey = toCamelCase(key)
    if (value instanceof Object && value instanceof Date === false) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      value = toCamelCase(value)
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
    final[newKey] = value
    return final
  }, {})
}

export function sortedIndex<T>(
  collection: T[],
  item: T,
  compare: (a: T, b: T) => number,
) {
  let low = 0,
    high = collection.length
  while (low < high) {
    const mid = (low + high) >>> 1
    // @ts expect-error noUncheckedIndexAccess
    if (compare(collection[mid], item) < 0) {
      low = mid + 1
    } else {
      high = mid
    }
  }
  return low
}

/**
 * Structurally verify that two objects are equal.
 *
 * When two instances of a class are compared, they are compared with
 * referential equality instead of recursing further.
 *
 * @param a - The first object to compare.
 * @param b - The second object to compare.
 *
 * @returns `true` if the objects are structurally equal, otherwise `false`.
 */
export const isReferentiallyDeepEqual = isEqualWith((a, b) => {
  if (
    isObjectLike(a) &&
    isObjectLike(b) &&
    !Array.isArray(a) &&
    !Array.isArray(b) &&
    !isPlainObject(a) &&
    !isPlainObject(b)
  ) {
    return a === b
  }
})
