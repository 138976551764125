import { makeObservable, observable } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { PhoneNumberModel, RawRingOrder } from './model'
import { RingOrderModel } from './model'
import type { RingOrderRepository } from './worker/repository/RingOrderRepository'
import { RING_ORDER_TABLE_NAME } from './worker/repository/RingOrderRepository'

export default class RingOrderStore {
  readonly collection: PersistedCollection<RingOrderModel, RingOrderRepository>

  private readonly disposeBag = new DisposeBag()
  private readonly fetchedRingOrdersByPhoneNumber = new Set<string>()
  private readonly defaultGroupSize = 10

  maxDialAtOnce = 20

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table(RING_ORDER_TABLE_NAME),
      classConstructor: (json) => new RingOrderModel(json as RawRingOrder),
    })

    makeObservable<this>(this, {
      collection: observable,
      maxDialAtOnce: observable,
    })

    this.disposeBag.add(this.subscribeToWebSocket())
    this.load()
  }

  getByPhoneNumberId(phoneNumberId: string): RingOrderModel | null {
    const localRingOrder = this.collection.find(
      (item) => item.phoneNumberId === phoneNumberId,
    )
    this.fetchRingOrderFromRemoteIfNeeded(phoneNumberId, localRingOrder)

    return localRingOrder ?? null
  }

  private async fetchRingOrderFromRemoteIfNeeded(
    phoneNumberId: string,
    localRingOrder: RingOrderModel | undefined,
  ) {
    // We want to fetch the remote ring order at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedRingOrdersByPhoneNumber.has(phoneNumberId)
    if (alreadyFetched) {
      return
    }

    const remoteRingOrder: RawRingOrder | undefined =
      await this.fetchByPhoneNumberId(phoneNumberId)
    this.fetchedRingOrdersByPhoneNumber.add(phoneNumberId)

    if (localRingOrder && !remoteRingOrder) {
      // If the remote ring order is deleted, replace the local one with the default
      this.collection.delete(localRingOrder.id)
      this.addDefaultRingOrderToCollection(phoneNumberId)
    } else if (localRingOrder && remoteRingOrder) {
      // If both local and remote exist, update local ring order with remote data
      localRingOrder.deserialize(remoteRingOrder)
    } else if (!localRingOrder && remoteRingOrder) {
      // If the ring order exists only on remote, add it to the local collection
      this.collection.put(new RingOrderModel(remoteRingOrder))
    } else if (!localRingOrder && !remoteRingOrder) {
      // If the ring order doesn't exist on both remote and local, add the default to the local collection
      this.addDefaultRingOrderToCollection(phoneNumberId)
    }
  }

  async update(ringOrder: RingOrderModel) {
    const response = await this.root.transport.voice.ringOrders.update(
      ringOrder.serialize(),
    )

    this.collection.put(new RingOrderModel(response.result))

    return response
  }

  async fetchMaxDialAtOnce() {
    this.maxDialAtOnce = (
      await this.root.transport.voice.ringOrders.maxDialAtOnce()
    ).maxDialAtOnce
  }

  private makeDefaultRingOrder(phoneNumber: PhoneNumberModel): RingOrderModel {
    return new RingOrderModel({
      type:
        phoneNumber.members.length <= this.defaultGroupSize ? 'ALL_AT_ONCE' : 'RANDOM',
      phoneNumberId: phoneNumber.id,
      duration: 30,
      groupSize:
        phoneNumber.members.length > 1
          ? Math.min(this.defaultGroupSize, Math.ceil(phoneNumber.members.length / 2))
          : 1,
      groups: [],
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      version: 1,
    })
  }

  private addDefaultRingOrderToCollection(phoneNumberId: string) {
    const phoneNumber = this.root.phoneNumber.collection.get(phoneNumberId)
    if (!phoneNumber) {
      return
    }
    this.collection.put(this.makeDefaultRingOrder(phoneNumber))
  }

  private load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  private async fetchByPhoneNumberId(phoneNumberId: string) {
    const response =
      await this.root.transport.voice.ringOrders.getAllByPhoneNumberId(phoneNumberId)

    if (!response) {
      return
    }

    const raw = response.result[0]

    return raw
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'ring-order-update': {
          const ringOrder = new RingOrderModel(data.ringOrder)
          this.collection.put(ringOrder)
          break
        }
        case 'ring-order-delete': {
          this.collection.delete(data.ringOrderId)
          break
        }
      }
    })
  }
}
