import { action, makeObservable, observable, reaction } from 'mobx'

import StatefulPromise from '@src/lib/StatefulPromise'
import { DisposeBag } from '@src/lib/dispose'
import makePersistable from '@src/service/storage/makePersistable'

import type Service from '.'
import type { RawTrustRegistrationV2, RawTrustRegistrationV2FormFields } from './model'
import { TrustRegistrationV2Model } from './model'

export default class TrustRegistrationV2Store {
  private readonly disposeBag = new DisposeBag()

  registration: TrustRegistrationV2Model | null = null

  private fetchRegistrationPromise = new StatefulPromise(this.handleFetch.bind(this))

  constructor(private readonly root: Service) {
    makeObservable<this, 'setRegistration'>(this, {
      registration: observable,
      setRegistration: action.bound,
      update: action.bound,
    })

    makePersistable(this, 'TrustRegistrationV2Store', {
      registration: root.storage.async(
        (data: RawTrustRegistrationV2) => new TrustRegistrationV2Model(data),
      ),
    })

    this.disposeBag.add(
      this.subscribeToWebSocket(),
      reaction(
        () => this.root.user.current?.asMember?.isAdmin,
        () => {
          // Reset fetch status when user becomes admin or loses admin status
          // This is to ensure that the fetch is re-triggered again when needed
          this.fetchRegistrationPromise.resetStatus()
        },
      ),
    )
  }

  tearDown() {
    this.disposeBag.dispose()
  }

  private handleFetch() {
    const isAdmin = this.root.user.getCurrentUser().asMember?.isAdmin ?? false
    if (isAdmin) {
      return this.root.transport.trust.registrationV2.fetch()
    } else {
      return this.root.transport.trust.registrationV2.fetchLite()
    }
  }

  async fetch() {
    if (this.fetchStatus === 'idle') {
      const response = await this.fetchRegistrationPromise.run()
      this.setRegistration(response)
    }
    return this.registration
  }

  get fetchStatus() {
    return this.fetchRegistrationPromise.status
  }

  async create(raw: Partial<RawTrustRegistrationV2FormFields>) {
    const json = await this.root.transport.trust.registrationV2.create(raw)

    this.registration = new TrustRegistrationV2Model(json)

    return this.registration
  }

  async update(fields: Partial<RawTrustRegistrationV2FormFields>) {
    if (!this.registration) {
      return null
    }

    const json = await this.root.transport.trust.registrationV2.update({
      id: this.registration.id,
      etag: this.registration.etag,
      ...fields,
    })

    return this.registration.localUpdate(json)
  }

  async delete() {
    if (!this.registration) {
      return
    }

    const currentRegistration = this.registration

    try {
      this.registration = null

      await this.root.transport.trust.registration.delete(
        currentRegistration.id,
        currentRegistration.etag,
      )
    } catch (error) {
      this.registration = currentRegistration
      throw error
    }
  }

  async resendBrandSMSVerification() {
    if (!this.registration) {
      return
    }

    await this.root.transport.trust.registrationV2.brandSms(this.registration.id)
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'reg-update':
          return this.setRegistration(data.registration as RawTrustRegistrationV2)
        case 'reg-delete':
          return this.setRegistration(null)
      }
    })
  }

  private setRegistration(
    json: RawTrustRegistrationV2 | null,
  ): TrustRegistrationV2Model | null {
    if (json === null || !json.id) {
      this.registration = null
      return this.registration
    }

    if (this.registration) {
      return this.registration.deserialize(json)
    }

    this.registration = new TrustRegistrationV2Model(json)

    return this.registration
  }
}
