import './css'

import { StylesProvider } from '@material-ui/core/styles'
import { observer } from 'mobx-react-lite'

import LazyConfetti from '@src/component/LazyConfetti'
import useMetaThemeColor from '@src/lib/hooks/useMetaThemeColor'
import useWindowSize from '@src/lib/hooks/useWindowSize'
import { ServiceContext } from '@src/service/context'
import { useAppTheme } from '@src/theme'
import DesignSystemProvider from '@ui/DesignSystemProvider'

import AppMediaViewer from './AppMediaViewer'
import type AppStore from './AppStore'
import './globalStyles.css'
import './global.css'
import WindowsTitleBar from './WindowsTitleBar'
import PricingProvider from './billing/pricing/PricingProvider'
import { useAppStore } from './context'
import { GlobalErrorBoundary } from './error'
import * as styles from './index.css'
import Prompt from './prompt/Prompt'
import Router from './router/Router'
import { Toast } from './toast'
import useFavicon from './useFavicon'

declare global {
  interface Window {
    app: AppStore
    dataLayer: any[]
  }
}

const App = function () {
  const { width, height } = useWindowSize()
  const store = useAppStore()
  const theme = useAppTheme(store.themeKey)
  useMetaThemeColor(theme)
  useFavicon()

  return (
    <StylesProvider injectFirst>
      <DesignSystemProvider
        themeKey={theme.palette.type}
        typographyThemeKey={store.typographyThemeKey}
      >
        <div className={styles.root}>
          {store.is('windows') && <WindowsTitleBar app={store} />}
          <div className={styles.body}>
            <ServiceContext.Provider value={store.service}>
              <PricingProvider>
                <GlobalErrorBoundary>
                  <Prompt />
                  <Toast />
                  <Router />
                  <AppMediaViewer />
                  {store.confetti && (
                    <LazyConfetti
                      recycle={false}
                      width={width}
                      height={height}
                      numberOfPieces={500}
                      tweenDuration={7000}
                      onConfettiComplete={() => store.resetConfetti()}
                    />
                  )}
                </GlobalErrorBoundary>
              </PricingProvider>
            </ServiceContext.Provider>
          </div>
        </div>
      </DesignSystemProvider>
    </StylesProvider>
  )
}

export default observer(App)
