import { useNavigate } from 'react-router-dom'

import Header from '@src/app/onboarding/Header'
import Button from '@src/component/ButtonV2'
import useMetaDescription from '@src/lib/hooks/useMetaDescription'
import useTitle from '@src/lib/hooks/useTitle'
import Typography from '@ui/Typography'
import { ContinueWithMagicCodeIcon, BrandEmailIcon } from '@ui/icons/custom'
import { GoogleIcon } from '@ui/icons/no-tint/misc'
import Spacer from '@ui/layout/Spacer'

import * as styles from './UniversalLogin.css'
import UniversalLoginLegalFooter from './UniversalLoginLegalFooter'

const UniversalLogin = () => {
  const navigate = useNavigate()

  useTitle('Log in')
  useMetaDescription(
    'Log back into your OpenPhone account or create an account to get started with a Free Trial.',
  )

  const handleLoginClick = () => {
    // TODO: implement in UXP-3035
  }

  const handleLoginClickPasswordless = () => {
    // TODO: implement in UXP-3035
  }

  const handleGoogleLogin = () => {
    // TODO: implement in UXP-3035
  }

  const handleNavigateToSignup = () => {
    navigate('/signup')
  }

  return (
    <>
      <Header />
      <div className={styles.background}>
        <div className={styles.card}>
          <Typography variant="largeTitle" fontWeight="medium">
            Welcome back!
          </Typography>
          <Spacer height={8} />
          <Typography variant="body" color="textSecondary">
            It's great to see you 👋
          </Typography>
          <Typography variant="body" color="textSecondary">
            Log in to your account below
          </Typography>
          <Spacer height={24} />
          <div className={styles.actionsContainer}>
            <Button
              fullWidth
              onClick={handleGoogleLogin}
              startIcon={<GoogleIcon />}
              variant="outlined"
              color="textPrimary"
            >
              <span className={styles.btnContent}>Continue with Google</span>
            </Button>
            <Button
              fullWidth
              onClick={handleLoginClickPasswordless}
              startIcon={<ContinueWithMagicCodeIcon />}
              variant="outlined"
              color="textPrimary"
            >
              <span className={styles.btnContent}>Continue with magic code</span>
            </Button>
            <Button
              fullWidth
              onClick={handleLoginClick}
              startIcon={<BrandEmailIcon />}
              variant="outlined"
              color="textPrimary"
            >
              <span className={styles.btnContent}>Continue with email</span>
            </Button>
          </div>
          <Spacer height={24} />
          <Typography variant="callout" color="textSecondary">
            Don’t have an account yet?
          </Typography>
          <Spacer height={4} />
          <button onClick={handleNavigateToSignup} className={styles.linkButton}>
            Sign up for free
          </button>
          <Spacer height={96} />
          <UniversalLoginLegalFooter />
        </div>
      </div>
    </>
  )
}

export default UniversalLogin
