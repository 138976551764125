import { useMediaQuery } from '@material-ui/core'

import type { StorageThemeKey } from './isStorageThemeKey'
import darkTheme from './themes/dark'
import lightTheme from './themes/light'

export default function useAppTheme(key: StorageThemeKey) {
  const preference = useMediaQuery('(prefers-color-scheme: dark)')

  switch (key) {
    case 'system':
      return preference ? darkTheme : lightTheme
    case 'light':
      return lightTheme
    case 'dark':
      return darkTheme
  }
}
