import type {
  CodableTrustRegistration,
  TrustRegistrationObject,
} from '@src/app/settings/trust/trustTypes'
import config from '@src/config'
import type {
  TollFreeRegistrationCreateRequest,
  TollFreeRegistrationCreateResponse,
  TollFreeRegistrationGetResponse,
  TollFreeRegistrationLiteGetResponse,
  TollFreeRegistrationSubmitRequest,
  TollFreeRegistrationSubmitResponse,
  TollFreeRegistrationUpdateRequest,
  TollFreeRegistrationUpdateResponse,
  TrustCnamCreateRequest,
  TrustCnamCreateResponse,
  TrustCnamDeleteRequest,
  TrustCnamDeleteResponse,
  TrustCnamGetAllResponse,
  TrustCnamUpdateRequest,
  TrustCnamUpdateResponse,
  TrustRegistrationV2CreateRequest,
  TrustRegistrationV2CreateResponse,
  TrustRegistrationV2GetResponse,
  TrustRegistrationV2LiteGetResponse,
  TrustRegistrationV2UpdateRequest,
  TrustRegistrationV2UpdateResponse,
} from '@src/service/dto'

import type Transport from '.'
import { HttpTransaction } from './transaction'

export default class TrustClient {
  constructor(private readonly transport: Transport) {}

  cnam = {
    getAll: (): Promise<TrustCnamGetAllResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/cnam`,
          query: {
            // FIXME: https://linear.app/openphone/issue/ENG-4182
            limit: 1000,
          },
        }),
      )
    },
    create: (dto: TrustCnamCreateRequest): Promise<TrustCnamCreateResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/cnam`,
          method: 'POST',
          body: dto,
        }),
      )
    },
    delete: (dto: TrustCnamDeleteRequest): Promise<TrustCnamDeleteResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/cnam/${dto.cnamId}`,
          method: 'DELETE',
          headers: {
            'If-Match': dto.etag,
          },
        }),
      )
    },
    update: (dto: TrustCnamUpdateRequest): Promise<TrustCnamUpdateResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/cnam/${dto.cnamId}`,
          method: 'PUT',
          body: {
            displayName: dto.displayName,
            useAsDefault: dto.useAsDefault,
            resourceIds: dto.resourceIds,
          },
          headers: {
            'If-Match': dto.etag,
          },
        }),
      )
    },
  }

  registration = {
    fetch: (): Promise<CodableTrustRegistration> => {
      return this.transport.queue(
        new HttpTransaction({ url: `${config.API_SERVICE_URL}trust/registration` }),
      )
    },
    create: (
      registration: Partial<TrustRegistrationObject>,
    ): Promise<CodableTrustRegistration> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration`,
          method: 'POST',
          body: registration,
        }),
      )
    },
    update: (
      id: string,
      etag: string,
      registration: Partial<TrustRegistrationObject>,
    ): Promise<CodableTrustRegistration> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration/${id}`,
          method: 'PUT',
          body: registration,
          headers: {
            'If-Match': etag,
          },
        }),
      )
    },
    delete: (id: string, etag: string): Promise<object> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration/${id}`,
          method: 'DELETE',
          headers: {
            'If-Match': etag,
          },
        }),
      )
    },
  }

  registrationV2 = {
    fetch: (): Promise<TrustRegistrationV2GetResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration`,
        }),
      )
    },
    fetchLite: (): Promise<TrustRegistrationV2LiteGetResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration/lite`,
        }),
      )
    },
    create: (
      dto: TrustRegistrationV2CreateRequest,
    ): Promise<TrustRegistrationV2CreateResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration`,
          method: 'POST',
          body: dto,
        }),
      )
    },
    update: ({
      etag,
      id,
      ...fields
    }: TrustRegistrationV2UpdateRequest): Promise<TrustRegistrationV2UpdateResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration/${id}`,
          method: 'PUT',
          body: fields,
          headers: {
            'If-Match': etag,
          },
        }),
      )
    },
    brandSms: (registrationId: string): Promise<void> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/registration/${registrationId}/brandSms`,
          method: 'POST',
        }),
      )
    },
  }

  tollFreeRegistration = {
    fetch: (): Promise<TollFreeRegistrationGetResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/tfn-registrations`,
        }),
      )
    },
    fetchLite: (): Promise<TollFreeRegistrationLiteGetResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/tfn-registrations/lite`,
        }),
      )
    },
    create: (
      dto: TollFreeRegistrationCreateRequest,
    ): Promise<TollFreeRegistrationCreateResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/tfn-registrations`,
          method: 'POST',
          body: dto,
        }),
      )
    },
    update: ({
      id,
      ...fields
    }: TollFreeRegistrationUpdateRequest): Promise<TollFreeRegistrationUpdateResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/tfn-registrations/${id}`,
          method: 'PATCH',
          body: fields,
        }),
      )
    },
    submit: ({
      id,
      ...fields
    }: TollFreeRegistrationSubmitRequest): Promise<TollFreeRegistrationSubmitResponse> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/tfn-registrations/${id}`,
          method: 'PUT',
          body: fields,
        }),
      )
    },
    delete: (id: string): Promise<object> => {
      return this.transport.queue(
        new HttpTransaction({
          url: `${config.API_SERVICE_URL}trust/tfn-registrations/${id}`,
          method: 'DELETE',
        }),
      )
    },
  }
}
