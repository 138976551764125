import { action, makeAutoObservable } from 'mobx'

import type Service from '.'
import type { Invite } from './transport/account'

class UniversalLoginAuthStore {
  referralCode: string | null = null
  invite: Invite | null = null

  constructor(private service: Service) {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  fetchAndSetInvite(code: string) {
    this.service.user.fetchInviteByToken(code).then(
      action((invite) => {
        this.invite = invite
      }),
    )
  }
}

export default UniversalLoginAuthStore
