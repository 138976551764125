import { action, makeAutoObservable } from 'mobx'

import StatefulPromise from '@src/lib/StatefulPromise'
import type Service from '@src/service'
import type {
  PaginatedInvoices,
  Invoice,
  InvoicePreviewParams,
  PaginatedInvoiceParams,
} from '@src/service/transport/billing'

export default class InvoicesStore {
  paginatedInvoices: PaginatedInvoices[] = []
  unpaidInvoices: Invoice[] = []
  upcomingInvoice: Invoice | null = null

  private fetchInvoicesPromise = new StatefulPromise(this.handleFetchInvoices.bind(this))

  constructor(private root: Service) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.subscribeToWebSocket()
  }

  get list() {
    return this.paginatedInvoices.flatMap((page) => page.invoices)
  }

  get lastInvoiceId(): string | null {
    return this.list.at(-1)?.id ?? null
  }

  get isFetchingInvoices(): boolean {
    return (
      this.fetchInvoicesPromise.status === 'loading' ||
      this.fetchInvoicesPromise.status === 'idle'
    )
  }

  get hasFetchedInvoices(): boolean {
    return this.fetchInvoicesPromise.status === 'success'
  }

  /**
   * Fetches the most recent page of invoices as well as the following page
   * if needed
   */
  private async handleFetchInvoices() {
    const firstPage = await this.fetchFirstInvoice()
    const pages = [firstPage]

    if (firstPage.hasMore && firstPage.invoices.length === 10) {
      const secondPage = await this.fetchNextInvoicePage(firstPage.endId)

      if (secondPage) {
        pages.push(secondPage)
      }
    }

    this.paginatedInvoices = pages
  }

  fetchInvoices() {
    return this.fetchInvoicesPromise.run()
  }

  private async fetchFirstInvoice() {
    // empty params object to fetch first page
    return this.fetchInvoicesPage({})
  }

  private fetchInvoicesPage(params?: PaginatedInvoiceParams) {
    return this.root.transport.billing
      .invoices({ after: params?.after })
      .then(this.normalizePaginatedInvoices)
  }

  private normalizePaginatedInvoices({ invoices, ...page }: PaginatedInvoices) {
    return {
      ...page,
      invoices: invoices.map(this.normalizeInvoice),
    }
  }

  /**
   * Stripe uses unix timestamps in seconds not milliseconds.
   * In order to not multiply every usage of the dates by 1000
   * to use milliseconds all invoices get normalized.
   */
  private normalizeInvoice(invoice: Invoice): Invoice {
    return {
      ...invoice,
      created: invoice.created * 1000,
      due_date: invoice.due_date ? invoice.due_date * 1000 : null,
    }
  }

  private async fetchNextInvoicePage(lastInvoiceId: string) {
    const page = await this.fetchInvoicesPage({ after: lastInvoiceId })

    return page
  }

  async handleFetchMore(): Promise<void> {
    if (!this.paginatedInvoices.at(-1)?.hasMore || !this.lastInvoiceId) {
      return
    }

    const page = await this.fetchNextInvoicePage(this.lastInvoiceId)

    this.paginatedInvoices.push(page)
  }

  fetchUpcomingInvoice() {
    return this.root.transport.billing.upcomingInvoice().then(
      action((res) => {
        this.upcomingInvoice = this.normalizeInvoice(res)
      }),
    )
  }

  fetchUnpaidInvoices() {
    return this.root.transport.billing.invoices({ unpaid: true, paginate: false }).then(
      action((unpaidInvoices) => {
        this.unpaidInvoices = (unpaidInvoices ?? []).map((invoice) =>
          this.normalizeInvoice(invoice),
        )
      }),
    )
  }

  fetchInvoicePreview(params: InvoicePreviewParams) {
    return this.root.transport.billing
      .invoicePreview(params)
      .then((invoice) => this.normalizeInvoice(invoice))
  }

  payInvoice(id: string) {
    return this.root.transport.billing.payInvoice(id)
  }

  private handleInvoiceUpdate(invoice: Invoice) {
    const { page, index } = this.findInvoiceIndex(invoice.id)
    const normalizedInvoice = this.normalizeInvoice(invoice)

    if (!page) {
      this.fetchInvoices()
    } else if (index !== null) {
      page.invoices.splice(index, 1, normalizedInvoice)
    }
  }

  private upsertUnpaidInvoice(invoice: Invoice) {
    const index = this.unpaidInvoices.findIndex(
      (localInvoice) => localInvoice.id === invoice.id,
    )

    const normalizedInvoice = this.normalizeInvoice(invoice)

    const isUnpaid = invoice.status === 'open'

    const isNotUnpaidAndWasNotSavedBefore = !isUnpaid && index === -1

    if (isNotUnpaidAndWasNotSavedBefore) {
      return
    }

    if (index === -1) {
      this.unpaidInvoices.unshift(normalizedInvoice)
    } else {
      this.unpaidInvoices.splice(index, 1, normalizedInvoice)
    }
  }

  private findInvoiceIndex(id: string) {
    let invoicePage: PaginatedInvoices | null = null
    let invoiceIndex: number | null = null

    for (const page of this.paginatedInvoices) {
      for (let i = 0; i < page.invoices.length; i++) {
        if (page.invoices[i]?.id === id) {
          invoicePage = page
          invoiceIndex = i
        }
      }
    }

    return { page: invoicePage, index: invoiceIndex }
  }

  private subscribeToWebSocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'invoice-update': {
          this.handleInvoiceUpdate(data.invoice)
          this.upsertUnpaidInvoice(data.invoice)
          return
        }
      }
    })
  }
}
