import isNonNull from '@src/lib/isNonNull'

import { isDate } from './date'

export const toQueryString = (obj: { [key: string]: any } | undefined | null): string => {
  if (!obj) {
    return ''
  }
  const params = Object.keys(obj)
    .map((key) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      let value = obj[key]
      if (value !== undefined && value !== null) {
        if (isDate(value)) {
          value = value.toISOString()
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        const parameter = `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        return parameter
      } else {
        return null
      }
    })
    .filter(isNonNull)

  if (params.length > 0) {
    return `?${params.join('&')}`
  }
  return ''
}

export const fromQueryString = (string?: string | null): { [key: string]: string } => {
  if (!string) {
    return {}
  }
  if (string.charAt(0) === '?' || string.charAt(0) === '#') {
    return fromQueryString(string.substr(1))
  }
  return string.split('&').reduce((hash, part) => {
    const [key, value] = part.split('=')
    // @ts expect-error noUncheckedIndexAccess
    hash[key] = decodeURIComponent(value)
    return hash
  }, {})
}
